.Wrapper {
  margin-top: 100px;
  display: flex;
  justify-content: space-between;
  gap: 96px;
  padding-right: 156px;
  align-items: center;
}

.Left {
  display: flex;
  width: 50%;
  position: relative;
}

.floatImage {
  position: absolute;
  left: 20%;
  bottom: 10%;
  width: 80%;
  object-fit: contain;
}

.Content {
  width: 50%;
}
.Background {
  width: 636px;
  height: 529px;
}

.BtnSeeMore {
  width: 264px;
  height: 51px;
  border-radius: 8px;
  background-color: #e1641c;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 36px;
  cursor: pointer;
}
.BtnSeeMore:hover {
  opacity: 0.9;
}

.CotentTitle {
  color: #447323;
  font-weight: 400;
  font-size: 42px;
  line-height: 60px;
  margin-bottom: 25px;
}

.CotentDesc {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #262626;
}

.MobileResponsive {
  display: none;
}

@media only screen and (max-width: 770px) {
  .MobileResponsive {
    display: block;
  }
  .DesktopResponsive {
    display: none;
  }
}
